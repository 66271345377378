
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components{
    .bannerIcon{
        @apply w-16 h-16 bg-black bg-opacity-25 text-gray-200 text-xl inline-flex
    items-center justify-center rounded-md shadow-shadowOne hover:bg-opacity-40 hover:translate-y-1 transition-all hover:text-designColor cursor-pointer duration-300

    }

    .resumeLi{
        @apply w-full h-20 bg-black bg-opacity-25 text-xl text-gray-300 flex justify-center cursor-pointer hover:bg-opacity-40 duration-300 items-center border-[1px] rounded-none;
    }
}
.bgOpacity{
    @apply bg-black bg-opacity-40
}
.contactInput{
     @apply w-full h-12 rounded-lg border-b-[1px] border-b-gray-600 bg-[#191b1e] text-ligthText px-4 active:outline-none focus-visible:outline-designColor outline-none focus-visible:border-b-transparent duration-300
}

.active{
    color: white;
}
.scrollbar-hide::-webkit-scrollbar{
    display: none;
}